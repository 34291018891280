import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
import { graphql, useStaticQuery } from 'gatsby'
import ContactForm from "../components/ContactForm"
import { ImHome } from "react-icons/im"
import { Link } from "gatsby"

const ContactPage = (props) => {
    const { contactpage } = useStaticQuery(graphql`
    query {
        contactpage: file(relativePath: {eq: "page/contact/index.md"}) {
            childMarkdownRemark {
                html
                frontmatter {
                    title
                    seotitle
                    seometadesc
                    featureimagealt
                    featureimage {
                        childImageSharp {
                            fluid(maxWidth: 4000, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp 
                            }
                        }
                    extension
                    publicURL
                    }
                }
            }
        }
    }
`)

const contact = contactpage.childMarkdownRemark
    return (
        <>
            <Layout location={props.location}>
                <SEO title={contact.frontmatter.seotitle} description={contact.frontmatter.seometadesc} image={contact.frontmatter.featureimage.publicURL}/>
                <HeroImage markdown={contact} />
                <section className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                      <ul className="text-left text-xs md:text-base">
                        <li className="inline-block">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-1">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                          <span className="px-1">{contact.frontmatter.seotitle}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="mx-auto px-2">
                        <div className="p-6">
                            <div className="text-left lg:text-justify cmscontent" dangerouslySetInnerHTML={{ __html: contact.html }} />
                        </div>
                        <ContactForm />
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ContactPage