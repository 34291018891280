import React from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'

const ContactForm = () => {
    const {contact} = useStaticQuery(graphql`
    query {
        contact: file(relativePath: {eq: "settings/settings-contact.md"}) {
            id
            childMarkdownRemark {
                frontmatter {
                    contformtitle
                    contformdesc
                    lblcontformfname
                    lblcontformlname
                    lblcontformtel
                    lblcontformloc
                    lblcontformemail
                    lblcontformmessage
                    lblcontformbtn
                    }
                }
            }
        }
    `)

    const contactdata = contact.childMarkdownRemark
    return (
        <>
            <section id="contact" className="container mx-auto px-3">
                <div className="py-20 bg-primary-pantonea rounded-lg text-center overflow-hidden">
                <h3 className="text-5xl text-white font-thin tracking-widest">{contactdata.frontmatter.contformtitle}</h3>
                <p className="mt-8 text-xl font-thin tracking-widest text-white">
                    {contactdata.frontmatter.contformdesc}
                </p>
                <div className="flex justify-center pt-10">
                    <form 
                        name="Contact" 
                        method="POST" 
                        data-netlify="true" 
                        action="/success/"
                        className="w-full max-w-3xl text-center p-6">
                    <input type="hidden" name="form-name" value="Contact" />
                    <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase text-white mb-2">
                                    {contactdata.frontmatter.lblcontformfname}
                                    <input type="text" name="firstname" className="appearance-none block w-full bg-white text-gray-700 border justify-evenlyrounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200" id="firstname" placeholder="first name" required />
                                </label>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase text-white mb-2">
                                    {contactdata.frontmatter.lblcontformlname}
                                    <input type="text" name="lastname" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500" id="lastname" placeholder="last name" required />
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label className="block uppercase text-white mb-2">
                                    {contactdata.frontmatter.lblcontformtel}
                                    <input type="tel" name="telephone" className="appearance-none block w-full bg-white text-gray-700 border justify-evenlyrounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200" id="telephone" placeholder="contact telephone number" />
                                </label>
                            </div>
                            <div className="w-full md:w-1/2 px-3">
                                <label className="block uppercase text-white mb-2">
                                    {contactdata.frontmatter.lblcontformloc}
                                    <input type="text" name="location" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500" id="location" placeholder="your location, e.g. Bishops Stortford" />
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                            <label className="block uppercase text-white mb-2">
                                {contactdata.frontmatter.lblcontformemail}
                                    <input type="email" name="email" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500" id="email" placeholder="your email address" required />
                                </label>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase text-white mb-2">
                                    {contactdata.frontmatter.lblcontformmessage}
                                    <textarea name="message" className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500" rows="10" id="message" placeholder="enquiry details" required />
                                </label>
                            </div>
                            <div className="w-full px-3 text-left">
                                <label className="block">
                                    <input className="mr-3 inline-block" name="gdpr" id="gdpr" type="checkbox" required />
                                    <span className="text-white">In order to submit your details to us, please provide consent to the terms of our <Link to="/privacy/">Privacy Policy</Link></span>
                                </label>
                            </div>
                        </div>
                        <p className="mt-8">
                            {/* <Button className="bg-white" buttonType="submit" size="xl">{contactdata.frontmatter.lblcontformbtn}</Button> */}
                            <button className="bg-white hover:text-white hover:bg-primary-darker rounded-xl text-black focus:outline-none transition duration-150 ease-in-out transform hover:scale-105 py-5 px-16 text-lg"
                            type="submit" >{contactdata.frontmatter.lblcontformbtn}</button>
                        </p>
                    </form>
                </div>
                </div>
            </section>
        </>
    )
}

export default ContactForm